.SelectDifficultyOrCategory {
  flex: 1;
  display: flex;
  flex-direction: column;

  background-image: url('~assets/images/games/taboo_board.jpg');
  background-size: contain;
  border-radius: 5px;
  margin: 1rem 0 0;
  padding: 2rem;
}

@media screen and (max-width: 64em) {
  .SelectDifficultyOrCategory {
    width: 100%;
  }
}

.SelectDifficultyOrCategoryContainer {
  background: #ffffffaa;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.SelectDifficultyOrCategoryIntro.passiveTile {
  text-align: center;
  align-self: center;
  width: 100%;
  max-width: 100%;
  margin: 0;
  color: #000;
  background-color: rgba(244, 244, 244, 0.8);
}

@media screen and (max-width: 64em) {
  .SelectDifficultyOrCategoryIntro {
    margin: 1rem 0;
  }
}

.SelectDifficultyChoices {
  align-items: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 56rem;
}

@media screen and (max-width: 64em) {
  .SelectDifficultyChoices {
    flex-direction: column;
    width: 100%;
  }
}

.SelectDifficultyChoice,
.SelectCategoryChoice {
  flex: 1 1 10rem;
  padding: 3rem 2rem;
  margin: 1rem;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 64em) {
  .SelectDifficultyChoice,
  .SelectCategoryChoice {
    width: 100%;
  }
}

.SelectDifficultyChoice.tile.onPrimary,
.SelectCategoryChoice.tile.onPrimary {
  background: #2eabba;
  border: 1px solid #62d1de;
}

.SelectDifficultyChoice.tile:not(.disabled).onPrimary.selected,
.SelectDifficultyChoice.tile:not(.disabled).onPrimary:hover {
  background: #64c5d1;
}

.SelectDifficultyChoiceStars {
  margin: .5rem 0 0;
  font-size: 24px;
  color: #aee8ec;
  transition: color .15s, background-color .15s;
}

.SelectDifficultyChoice:hover .SelectDifficultyChoiceStars,
.SelectDifficultyChoice.selected .SelectDifficultyChoiceStars {
  color: #ffeb9b;
}

.SelectCategoryChoices {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 56rem;
}

@media screen and (max-width: 64em) {
  .SelectCategoryChoices {
    display: block;
    width: 100%;
    margin: 1rem 0 0;
  }

  .SelectCategoryChoice {
    width: auto;
    margin: 0 0 2rem;
  }
}

.SelectCategoryChoiceText {
  min-height: 5rem;
}

@media screen and (max-width: 64em) {
  .SelectCategoryChoiceText {
    min-height: auto;
  }
}

.SelectCategoryChoiceIcon {
  margin: 1rem 0;
}

@media screen and (max-width: 64em) {
  .SelectCategoryChoiceText {
    margin: 1rem 0 0;
  }
}

.SelectCategoryChoiceIconSvg {
  width: 4rem;
  height: 4rem;
  fill: #999;
  fill: #aee8ec;
}

.SelectCategoryChoice:hover .SelectCategoryChoiceIconSvg,
.SelectCategoryChoice.selected .SelectCategoryChoiceIconSvg {
  fill: #ffeb9b;
}

.SelectCategoryChoice.tile:not(.disabled).onPrimary.selected,
.SelectCategoryChoice.tile:not(.disabled).onPrimary:hover {
  background: #64c5d1;
}
