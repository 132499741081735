.AdminWhoisit {
  flex: 1;
}

.AdminWhoisitCardsWrapper {
  padding: 2rem 0;
}

.AdminWhoisitLinks {
  display: flex;
}

button.AdminWhoisitCreateCard {
  display: flex;
  align-items: center;
}

.AdminWhoisitCreateCardIcon {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  margin: 0 10px 0 0;
  fill: #fff;
}
