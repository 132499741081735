.AdminTabooCards table {
  margin: 1rem 0;
}

.AdminTabooCards table td input {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  border: 0;
}

.AdminTabooCards table th {
  padding: 0;
}

.AdminTabooCards table th:first-child,
.AdminTabooCards table td:first-child {
  text-align: center;
}

.AdminTabooCards table th:last-child,
.AdminTabooCards table td:last-child {
  width: 14rem;
  text-align: center;
}

.AdminTabooCards table .SortBy {
  display: block;
  padding: .5em 1em;
  position: relative;
}

.AdminTabooCards table .SortArrow {
  position: absolute;
  margin: 0 0 0 5px;
}

.AdminTabooCards .pagination {
  padding: 0.5rem;
}

