.CardFormInputRow {
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
}

.CardFormInputRow label {
  display: block;
  margin: 0 0 .3rem;
}

.CardFormInputRow input,
.CardFormInputRow textarea {
}

.CardFormInputRow textarea {
  height: 7rem;
}

.CardFormImageInput {
}

.CardFormImagePreview {
  padding: 1rem 0;
}

.CardFormImagePreview img {
  max-width: 100%;
}

.CardFormImagePreview img {
  max-width: 50%;
  max-height: 300px;
}

.CardFormImageCrop {
  padding: 1rem 0;
  width: 20rem;
}

.CardFormSave {
  display: flex;
  flex-direction: column;
  align-items: center;
}
