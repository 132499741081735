.TabooCreateInstance {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TabooLoading {
  flex: 1;
  display: flex;
  padding: 3rem 0 0;
}

.TabooTitle {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 1rem;
  padding: 0.5rem;

  background-image: url('~assets/images/games/taboo.jpg');
  background-size: cover;
  color: #fff;
  width: 20rem;
  text-align: center;
}

.TabooTitleText {
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 0.2rem;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 8px #000;
  word-wrap: break-word;
}

.BackToGames {
  align-self: flex-start;
}

.SubmitDifficulty button {
  color: red;
}

.TabooCreateInstanceButtons {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0;
}

.TabooCreateInstanceButtons .TabooCreateInstanceSubmit.button {
  align-self: center;
  padding: 1.5rem 4rem;
}

.TabooCreateInstanceButtons .TabooCreateInstanceBack.button {
  align-self: center;
  margin: 3rem 0 0;
}
