.Whoisit {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WhoisitLoading {
  flex: 1;
  display: flex;
  padding: 3rem 0 0;
}

.WhoisitTitle {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 1rem;
  padding: 0.5rem;

  background-image: url('~assets/images/games/whoisit.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  color: #fff;
  width: 20rem;
  text-align: center;
}

.WhoisitTitleText {
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 0.2rem;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 8px #000;
  word-wrap: break-word;
}

.BackToGames {
  align-self: flex-start;
}
