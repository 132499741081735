.WhoisitCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 0 14px rgba(0,0,0,0.05);
  overflow: hidden;
}

.WhoisitCardName {
  flex: 0;
  font-weight: bold;
  padding: 1rem;
  font-size: 2rem;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.WhoisitCardImage {
  flex: 1 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.WhoisitCardImage img {
  width: 100%;
}
