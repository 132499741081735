.Games {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2rem 0 0;
}

@media screen and (max-width: 64em) {
  .Games {
    display: block;
    margin: 0;
  }
}


.GamesEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.GamesNothingToDo {
  flex: none;
  max-width: 50%;
  padding: 2rem 3rem;
  background: hsla(0,0%,95.7%,.8);
  border-radius: 5px;
}
