.HangedmanGameTitle {
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 1rem;
    padding: 0.5rem;

    background-image: url('~assets/images/games/hangedman.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 25%;
    color: #fff;
    width: 36rem;
    text-align: center;
    border-radius: 5px
}

@media screen and (max-width: 64em) {
    .HangedmanGameTitle {
        max-width: 100%;
    }
}

.HangedmanGameTitleText {
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem 0.2rem;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    text-shadow: 0 0 8px #000;
    word-wrap: break-word;
}
