.LoginBottomLinks {
  margin: 1rem 0 0;
  text-align: center;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.LoginBottomLinks > * {
  margin: .5rem 0;
}
