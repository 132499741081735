.Desktop {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.DesktopHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 64em) {
  .DesktopHeader {
    overflow: hidden;
  }

  .Desktop.withUsers .DesktopHeader {
    flex-direction: column;
  }
}

@media screen and (max-width: 64rem) {
  .DesktopHeader {
    overflow: hidden;
  }

  .Desktop.withUsers .DesktopHeader {
    flex-direction: column;
  }
}

.DesktopHeaderLeft {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

@media screen and (max-width: 64rem) {
  .DesktopHeaderLeft {
    width: 100%;
    justify-content: flex-start;
  }
}

.DesktopHeaderRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding: 1rem 2rem;
}

@media screen and (max-width: 64rem) {
  .DesktopHeaderRight {
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0.5rem 1rem 1rem;
    width: 100%;
  }
}

.DesktopHeaderMenu {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: 1rem;
  border-left: 1px solid #555;
}

.DesktopHeaderMenuLink,
.DesktopHeaderMenuLink:visited {
  align-items: center;
  display: flex;
  padding: 0 2rem;
  border-right: 1px solid #555555;
  color: #fff;
  text-decoration: none;
}

.DesktopHeaderMenuLink:hover,
.DesktopHeaderMenuLink:active {
  background-color: #3c3c3c;
  text-decoration: none;
}

.DesktopHeaderMenuLinkActive,
.DesktopHeaderMenuLinkActive:hover,
.DesktopHeaderMenuLinkActive:active {
  background-color: #2c2c2c;
  text-decoration: none;
  box-shadow: inset 25px 0px 25px -25px rgba(0,0,0,0.45), inset -25px 0px 25px -25px rgba(0,0,0,0.45);
}

.DesktopHeaderTitle {
  align-self: flex-start;
  font-size: 22px;
  padding: 1rem 2rem;
}

.DesktopHeaderConnectedUsersTooltip {
  cursor: default;
}

.DesktopHeaderConnectedUsersTooltipOverlay {
}

.DesktopUsers {
  margin: 0 2rem 0;
  color: #aaa;
}

@media screen and (max-width: 64rem) {
  .DesktopUsers {
    padding: 0 1rem 0 0;
  }
}

@media screen and (max-width: 34rem) {
  .DesktopUsers {
    display: none;
  }

}

@media screen and (max-width: 64rem) {
  .DesktopCurrentUser {
    text-align: center;
    max-width: 100%;
  }
}

.DesktopUsername {
  font-weight: bold;
}

.DesktopLanguage {
  margin: 0 0 0 1rem;
}

.DesktopLogout {
  margin: 0 0 0 2rem;
}

@media screen and (max-width: 64rem) {
  .DesktopLogout {
    flex: 0;
    margin: 0;
    padding: 0 0 0 1rem;
  }
}

.DesktopContent {
  flex: 1;
  display: flex;
  height: 100%;
  padding: 2rem;
  overflow: auto;
  background-image: url(~assets/images/spetzgart01.jpg);
  background-size: cover;
  /*
  background: radial-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.7)),
    url(https://st2.depositphotos.com/2375273/11615/i/950/depositphotos_116156942-stock-photo-texture-of-green-rubber-floor.jpg);
  */
}

@media screen and (max-width: 64em) {
  .DesktopContent {
    display: block;
  }
}
