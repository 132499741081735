.Notifications {
  z-index: 1100;
  position: relative;
}

.Notifications .Toastify {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  transform: translate3d(0, 0, 0);
}

.Notifications  .Toastify__toast-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  top: 0;
  left: auto;
  margin-left: 0;
  padding: 0;
}

.Notifications .Toastify__toast-body {
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 600px;
}

.Notifications .Toastify__close-button {
  margin: 0 0 0 10px;
}

.Notifications .Toastify__toast--success {
  background-color: #71ab57;
}

.Notifications .Toastify__toast--error {
  background-color: #d75940;
}

.Notifications .Toastify__toast {
  min-height: 35px;
  padding: 8px 15px;
  text-align: center;
  box-shadow: none;
  margin: 0 0 1px;
  border-radius: 5px;
  opacity: 0.9;
  cursor: default;
}

.Notifications .Toastify__toast:first-child {
  border-radius: 0 0 5px 5px;
}

.NotificationsEaseOutCubicEnter {
  animation-name: NotificationsEaseOutCubic-in;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.NotificationsEaseOutCubicExit {
  animation-name: NotificationsEaseOutCubic-out;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes NotificationsEaseOutCubic-in {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes NotificationsEaseOutCubic-out {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
