.AdminTabooCardsEditButton {
  display: flex;
  align-items: center;
}

.AdminTabooCardsEditButtonIcon {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin: 0 .5rem;
  fill: #fff;
}
