.Page404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.Page404Content {
  flex: none;
  max-width: 50%;
  font-size: 24px;
  text-align: center;
}
