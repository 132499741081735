.AdminTaboo {
  flex: 1;
}

.AdminTabooCardsWrapper {
  padding: 2rem 0;
}

.AdminTabooLinks {
  display: flex;
}

button.AdminTabooCreateCard {
  display: flex;
  align-items: center;
}

.AdminTabooCreateCardIcon {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  margin: 0 10px 0 0;
  fill: #fff;
}
