.ModalContent {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 110px;
  min-width: 570px;
  max-height: 100vh;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 9px 1px rgba(0,0,0,.37);
  overflow: hidden;
}

.ModalContentHeader {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 1rem;
  flex-grow: 0;
  border-bottom: 1px solid #eee;
}

.ModalContentHeaderTitle {
  font-size: 1.25rem;
  font-weight: 500;
}

.ModalContentHeaderClose {
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  border: 0;
  background: transparent;
  outline: none;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

.ModalContentHeaderLeft {
  display: flex;
  flex: 1 1;
  margin-right: 35px;
}

.ModalContentHeaderRight {
  display: flex;
  justify-content: flex-end;
}

.ModalContentBody {
  overflow: auto;
  flex: 1 1;
  height: 100%;
  padding: 1rem;
}

.ModalContentLoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
