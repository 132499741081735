.WhoisitCreateInstance {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WhoisitLoading {
  flex: 1;
  display: flex;
  padding: 3rem 0 0;
}

.BackToGames {
  align-self: flex-start;
}

.WhoisitCreateInstanceButtons {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0;
}

.WhoisitCreateInstanceButtons .WhoisitCreateInstanceSubmit.button {
  align-self: center;
  padding: 1.5rem 4rem;
}

.WhoisitCreateInstanceButtons .WhoisitCreateInstanceBack.button {
  align-self: center;
  margin: 3rem 0 0;
}
