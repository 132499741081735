.TabooGame {
  display: flex;
  flex-direction: row;
  margin: 1rem 0 0;

  background-image: url('~assets/images/games/taboo_board.jpg');
  background-size: contain;
  border-radius: 5px;
  padding: 2rem;
}

@media screen and (max-width: 64em) {
  .TabooGame {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 26em) {
  .TabooGame {
    padding: 0;
  }
}

.TabooBoard {
  display: flex;
  background: rgba(244, 244, 244, 0.8);
  background: #f4f4f4cc;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 0px #00000044;
}

@media screen and (max-width: 64em) {
  .TabooBoard {
    flex-direction: column;
    padding: 0 0 2rem 0;
  }
}

.TabooControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 2rem 2rem 2rem;
  padding: 3rem;
  margin: 0;
  padding: 3rem 0 3rem 3rem;
}

@media screen and (max-width: 64em) {
  .TabooControls {
    flex-direction: column-reverse;
    padding: 2rem;
    margin: 0;
    width: 100%;
    max-width: 36rem;
  }
}

.TabooPickCard {
  margin: 0 0 2rem;
  margin: 0;
}

@media screen and (max-width: 64em) {
  .TabooPickCard {
    margin: 1rem 0 0;
  }
}

.TabooPickCardButton.button.primary {
  width: 100%;
  margin: 0;
  background-color: #2bafb6;
}

.TabooPickCardButton.button.primary:hover {
  background-color: #5ec4ca;
}

@media screen and (max-width: 64em) {
  .TabooPickCardButton.button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: left;
    padding: 2rem;
  }
}

.TabooPickCardButtonIcon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 0 1rem -2px 0;
  fill: #fff;
}

@media screen and (max-width: 64em) {
  .TabooPickCardButtonIcon {
    height: 4rem;
    flex: 0 0 4rem;
    margin: 0 2rem 0 0;
  }
}

.TabooAvailableCardsCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 0;
  font-size: 12px;
}

button.TabooPickCardResetButton.button {
  margin: 1rem 0;
  font-weight: bold;
}

.TabooChangeFilterButton.button {
  margin: 1rem 0 0;
  padding: 1rem;
}

@media screen and (max-width: 64em) {
  .TabooChangeFilterButton.button {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.TabooChangeFilterButtonIcon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin: 0 .6rem -2px 0;
  fill: #fff;
}

@media screen and (max-width: 64em) {
  .TabooChangeFilterButtonIcon {
    height: 2rem;
    margin: 0 1rem 0 0;
    width: auto;
  }
}

.TabooPickCardResetButtonIcon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin: 0 .7rem -2px 0;
  fill: #fff;
}

.TabooPickedCard {
  display: flex;
  width: 22rem;
  min-height: 28rem;
  padding: 3rem;
  margin: 0 2rem 2rem 2rem;
}

@media screen and (max-width: 64em) {
  .TabooPickedCard {
    width: 100%;
    justify-content: center;
    max-width: 36rem;
    margin: 0;
    padding: 0 2rem;
  }
}
