.LoginFormField {
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
}

.LoginFormFieldLabel {
  margin: 0;
}

.LoginFormFieldInput {
  margin: .5rem 0;
  display: flex;
}

.LoginFormFieldInput input {
  flex: 1;
}
