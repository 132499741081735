.LanguageSwitch {
}

.LanguageSwitchCurrentIcon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 0 -6px;
}

.LanguageSwitchMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  margin: 1.25rem 0 0 -.75rem;
  border-top: 1px solid #1a1a1a;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
  background: #333;
}

.LanguageSwitchMenuEntry {
  flex: 1;
  width: 100%;
  padding: .5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.LanguageSwitchMenuEntry:hover {
  background: #555;
}

.LanguageSwitchMenuEntry.current {
  opacity: .5;
  cursor: default;
}

.LanguageSwitchMenuEntry.current:hover {
  background: #333;
}

.LanguageSwitchMenuEntryIcon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

