html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  overflow: hidden;
}

@media screen and (max-width: 64em) {
  body {
    overflow-y: visible;
  }

  #root {
    display: block;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
  outline: none;
}

a,
a:visited {
  color: #d39800;
  color: #3476ba;
  cursor: pointer;
  text-decoration: none;
}

a:hover,
a:active {
  color: #f4b000;
  color: #2490ff;
  text-decoration: none;
}

a:focus {
  outline: 1px dotted #f4b000;
  outline: 1px dotted #2490ff;
}

.button,
a.button {
  display: inline-block;
  border: 0;
  border-radius: 5px;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: color .15s, background-color .15s;
}

.button + .button {
  margin-left: 1rem;
}

.button.small {
  font-size: 12px;
  padding: .5rem 1rem;
  font-weight: 600;
}

.button,
.button.medium {
  font-size: 14px;
  padding: 1rem 2rem;
  font-weight: 500;
}

.button.large {
  font-size: 20px;
  padding: .75rem 1.25rem;
  font-weight: 500;
}

.button.xlarge {
  font-size: 26px;
  padding: 1.25rem 1.75rem;
  font-weight: 500;
}

.button.primary.small,
.button.primary.medium,
.button.primary.large,
.button.primary.xlarge {
  font-weight: 700;
}

.button.primary,
.button.primary.onPrimary,
.button.primary.onSecondary {
  background-color: #efb60e;
  background-color: #3a658e;
}

.button.primary:hover,
.button.primary.onPrimary:hover,
.button.primary.onSecondary:hover {
  background-color: #fcc10f;
  background-color: #3d729d;
}

.button.secondary {
  background-color: #707070;
}

.button.secondary:hover {
  background-color: #828282;
}

.button.secondary.onSecondary {
  background-color: #555;
}

.button.warning {
  background-color: #bc4330;
}

.button.primary[disabled] {
  opacity: 0.3;
}

.button.secondary[disabled] {
  opacity: 0.12;
}

.primaryBg {
  background-color: #fff;
  color: #333;
}

.secondaryBg {
  background-color: #333;
  color: #fff;
}

.tile {
  border-radius: 5px;
  transition: color .15s, background-color .15s;
}

.tile.small {
  padding: .5rem;
}

.tile.medium {
  padding: 1rem;
}

.tile.large {
  padding: 2rem;
}

.tile.onPrimary {
  color: #fff;
  background-color: #333;
  border: 1px solid #222;
}

.tile:not(.disabled).onPrimary:hover,
.tile:not(.disabled).onPrimary.selected {
  color: #fff;
  background-color: #efb60e;
  background-color: #3a658e;
  border: 1px solid #bc900f;
  border: 1px solid #365b81;
}

.tile.onPrimary a {
  color: #fff;
}

.tile.disabled.onPrimary,
.tile.disabled.onPrimary:hover,
.tile.disabled.onPrimary a {
  color: #777;
}

.tile.onSecondary {
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
}

.tile:not(.disabled).onSecondary:hover,
.tile:not(.disabled).onSecondary.selected {
  color: #333;
  background-color: #efb60e;
  background-color: #3a658e;
  border: 1px solid #bc900f;
  border: 1px solid #365b81;
}

.tile.onSecondary a {
  color: #333;
}

.tile.disabled.onSecondary,
.tile.disabled.onSecondary:hover,
.tile.disabled.onSecondary.selected,
.tile.disabled.onSecondary a {
  color: #ccc;
}

a.tile {
  text-decoration: none;
}

.panel {
  background-color: #f4f4f4cc;
  border-radius: 5px;
}

.panel.padded {
  padding: 3rem;
}

.card {
  border-radius: 5px;
}

.card.onPrimary {
  color: #fff;
  background-color: #333;
  border: 1px solid #222;
}

.card.onSecondary {
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
}

.passiveTile {
  padding: 1rem;
  color: #d4d4d4;
  background: #33333366;
  border-radius: 5px;
}

label {
  display: block;
}

input,
textarea,
select {
  display: block;
  padding: .5rem;
  border: 1px solid #ccc;
}

table {
  width: 100%;
  min-width: 44em;
  border: 1px solid #cbcbcb;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

table caption {
  color: #000;
  font: italic 85%/1 arial,sans-serif;
  padding: 1em 0;
  text-align: center;
}

table thead {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
}

table td,
table th {
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  padding: .5em 1em;
}

table th {
  border-left: 1px solid #ccc;

}
table td {
  border-left: 1px solid #ddd;
  background-color: #fff;
  border-bottom: 1px solid #cbcbcb;
}

table tr:nth-child(even) td {
  background-color: #f8f8f8;
}

table tbody > tr:last-child > td {
  border-bottom-width: 0;
}

table th.highlight {
  background-color: rgb(255, 234, 133);
}
table td.highlight {
  background-color: rgb(255, 250, 229);
}

@media screen and (min-width: 48em) {

  .table-responsive {
    margin-left: auto;
    margin-right: auto;
  }
  .table-responsive table {
    border-left-width: 1px;
    border-right-width: 1px;
  }
}

.App {
  display: flex;
  height: 100%;
}

@media screen and (max-width: 64em) {
  .App {
    display: block
  }
}

.AppLoading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
