.HangedmanCreateInstance {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BackToGames {
  align-self: flex-start;
}

.HangedmanCreateInstanceButtons {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0;
}

.HangedmanCreateInstanceButtons .HangedmanCreateInstanceSubmit.button {
  align-self: center;
  padding: 1.5rem 4rem;
}

.HangedmanCreateInstanceButtons .HangedmanCreateInstanceBack.button {
  align-self: center;
  margin: 3rem 0 0;
}
