.GameTile {
  display: flex;
  flex: 0 0 30%;
  height: 100%;
  max-height: 30rem;
}

@media screen and (max-width: 64em) {
  .GameTile {
    width: auto;
    height: auto;
    margin: 0 0 2rem;
  }
}

.GameTile:not(.disabled):hover {
  box-shadow: inset 0 0 0 2000px #ffffff44;
}

.GameTile + .GameTile {
  margin-left: 2rem;
}

@media screen and (max-width: 80em) {
  .GameTile + .GameTile {
    margin: 0 0 1rem;
  }
}

@media screen and (max-width: 64em) {
  .GameTile + .GameTile {
    margin: 0 0 2rem;
  }
}

.GameTile.disabled {
  opacity: .3;
}

.GameTile.disabled a {
  cursor: default;
}

.GameTileLink {
  flex: 1;
  text-align: center;
  padding: 2rem;
  text-decoration: none;
  background: rgba(255,255,255,0.1);
  position: relative;
  overflow: hidden;
}

.GameTileTitle {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 2rem;
  padding: 1rem .2rem;
  border-radius: 5px;
  background: rgba(0,0,0,0.5);
  text-shadow: 0 0 8px #000;
  word-wrap: break-word;
}

@media screen and (max-width: 64em) {
  .GameTileTitle {
    margin: 0;
    border-radius: 5px 5px 0 0;
    padding: 3rem 0 0;
  }
}

.GameTileDescription {
  font-size: 20px;
  padding: 1rem;
  border-radius: 5px;
  background: rgba(0,0,0,0.5);
  text-shadow: 0 0 8px #000;
}

@media screen and (max-width: 64em) {
  .GameTileDescription {
    padding-bottom: 3rem;
  }
}

.GameTileTaboo {
  background-image: url('~assets/images/games/taboo.jpg');
  background-size: cover;
}

.GameTileWhoIsIt {
  background-image: url('~assets/images/games/whoisit.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
}

.GameTileWhoIsIt .GameTileTitle,
.GameTileWhoIsIt .GameTileDescription {
  background: #00000090;
}

.GameTileHangedMan {
  background-image: url('~assets/images/games/hangedman.jpg');
  background-size: cover;
  background-position: 60% 129px;
}
