.HangedmanGame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 3rem;
    padding: 2rem 3rem 4rem
}

@media screen and (max-width: 64em) {
    .HangedmanGame {
        width: 100%;
        padding: 1rem 0;
    }
}
