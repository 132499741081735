.Login {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 640px;
  max-width: 100%;
  padding: 2rem;
  margin: 0 auto;
}

@media screen and (max-width: 40em) {
  .Login {
    padding: 0;
  }
}

.LoginForm {
  display: flex;
  width: 100%;
}

.LoginFormHorizontal {
  flex-direction: row;
}

@media screen and (max-width: 40em) {
  .LoginFormHorizontal {
    flex-direction: column;
  }
}

.LoginFormVertical {
  flex-direction: column;
}

.LoginByName,
.LoginByCredentials,
.CreateCredentials {
  width: 100%;
}



@media screen and (max-width: 40em) {
  .LoginByName.panel.padded,
  .LoginByCredentials.panel.padded,
  .CreateCredentials.panel.padded {
    padding: 1.5rem;
  }
}

.LoginByName {
  margin-bottom: 50px;
}

@media screen and (max-width: 40em) {
  .LoginByName .LoginNameInput {
    margin-bottom: 1rem;
  }
}

.LoginByName .LoginNameInput,
.CreateCredentials .LoginNameInput {
  flex: 1;
  font-size: 1.4rem;
  padding: .5rem;
}

.LoginByCredentials .LoginEmailInput,
.CreateCredentials .LoginEmailInput {
  flex: 1;
  font-size: 1.4rem;
  padding: .5rem;
}

.LoginByCredentials .LoginPasswordInput,
.CreateCredentials .LoginPasswordInput {
  flex: 1;
  font-size: 1.4rem;
  padding: .5rem;
}

@media screen and (max-width: 40em) {
  .LoginByCredentials .LoginNameInput,
  .CreateCredentials .LoginNameInput,
  .LoginByCredentials .LoginEmailInput,
  .CreateCredentials .LoginEmailInput,
  .LoginByCredentials .LoginPasswordInput,
  .CreateCredentials .LoginPasswordInput {
    width: 100%;
  }
}

.LoginSubmit {
  font-size: 1.4rem;
  font-weight: bold;
}

.LoginFormHorizontal .LoginSubmit {
  padding: 0 .5rem;
}

.LoginFormVertical .LoginSubmit {
  padding: .5rem;
}
