.HangedmanRound {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 64em) {
    .HangedmanRound {
        width: 100%;
    }
}

.HangedmanGameBoard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;
    background-image: url('~assets/images/games/hangedman_board.png');
    background-size: cover;
}

@media screen and (max-width: 64em) {
    .HangedmanGameBoard {
        flex-direction: column;
        width: 100%;
    }
}

.HangedmanGameCanvas {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 2rem 2rem;
}

@media screen and (max-width: 64em) {
    .HangedmanGameCanvas {
        overflow: hidden;
        padding: 2rem 0 2rem 4rem;
    }
}

.HangedmanGameCanvas canvas {
    transform: scaleX(-1);
}

.HangedmanGameControls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3rem 0 0;
}

@media screen and (max-width: 64em) {
    .HangedmanGameControls {

    }
}

.HangedmanPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 2rem 0;
}

@media screen and (max-width: 64em) {
    .HangedmanPanel {
        padding: 1rem 0;
        flex-direction: column;
    }
}

.HangedmanWordDiscovered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4rem;
    margin: 0 0 2rem;
    padding: 0 2rem;
    width: 100%;
}

@media screen and (max-width: 64em) {
    .HangedmanWordDiscovered {
        width: 100%;
        height: auto;
    }
}

.HangedmanWordDiscoveredLetter {
    margin: 0 0.25rem;
    font-family: monospace;
    font-size: 2.5rem;
    font-weight: bold;
    color: #b6b6b6;
    color: #5d917e;
    border-bottom: 3px solid #ffffff;
}

.HangedmanWordDiscoveredLetter.discovered {
    color: #ffffff;
}

.HangedmanRoundWon .HangedmanWordDiscoveredLetter.discovered,
.HangedmanRoundLost .HangedmanWordDiscoveredLetter.discovered {
    /*color: #2aaf29;*/
    color: #96e05e;
}

.HangedmanRoundLost .HangedmanWordDiscoveredLetter:not(.discovered) {
    /*color: #ff4841;*/
    color: #dc615c;
}

.HangedmanWordDiscoveredLetter.empty {
    color: #ffffff00;
}

.HangedmanKeyboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 36rem;
    height: 12rem;
    padding: 1rem;
    position: relative;
    border-radius: 5px;
    background: #333333aa;
}

@media screen and (max-width: 64em) {
    .HangedmanKeyboard {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 0 0 2rem;
    }
}

.HangedmanRoundWon .HangedmanKeyboard,
.HangedmanRoundLost .HangedmanKeyboard {
    background: #545454aa;
}

.HangedmanKeyboardLayoutRow {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 64em) {
    .HangedmanKeyboardLayoutRow {
        flex-wrap: wrap;
        width: 100%;
    }
}

.HangedmanKeyboardLetter {
    flex: 0 0 2.5rem;
    height: 2.5rem;
    margin: 0.4rem;
    font-size: 1.5rem;
    font-family: monospace;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgba(0,0,0,.3);
    box-sizing: border-box;
    background-color: #fff;
    cursor: default;
}

@media screen and (max-width: 64em) {
    .HangedmanKeyboardLetter {
        flex: 0 0 3rem;
        height: 3rem;
    }
}

.HangedmanKeyboardLetter.interactive {
    cursor: pointer;
}

.HangedmanKeyboardLetter.interactive:hover,
.HangedmanKeyboardLetter.interactive:active {
    background-color: #fcc10f;
    background-color: #a6d3c3;
}

.HangedmanKeyboardLetter.proposing {
    opacity: .3;
}

.HangedmanEndRoundButton.button.primary {
    margin: 2rem 0 0;
    background-color: #408666;
    background-color: #009654;
}

.HangedmanEndRoundButton.button.primary:hover,
.HangedmanEndRoundButton.button.primary:focus {
    background-color: #549d7b;
    background-color: #00bd77;
}

.HangedmanEndRoundButton[disabled] {
    cursor: default;
}

.HangedmanRoundWon .HangedmanKeyboardLetter,
.HangedmanRoundLost .HangedmanKeyboardLetter {
    background-color: #dededeaa;
}

.HangedmanRoundWon .HangedmanKeyboardLetter,
.HangedmanRoundLost .HangedmanKeyboardLetter,
.HangedmanRoundPlayer .HangedmanKeyboardLetter,
.HangedmanRoundWon .HangedmanKeyboardLetter:hover,
.HangedmanRoundLost .HangedmanKeyboardLetter:hover,
.HangedmanRoundPlayer .HangedmanKeyboardLetter:hover,
.HangedmanRoundWon .HangedmanKeyboardLetter:active,
.HangedmanRoundLost .HangedmanKeyboardLetter:active,
.HangedmanRoundPlayer .HangedmanKeyboardLetter:active {
    cursor: default;
    border-style: outset;
}

.HangedmanRound .HangedmanKeyboardLetter.proposed,
.HangedmanRound .HangedmanKeyboardLetter.proposed:hover,
.HangedmanRound .HangedmanKeyboardLetter.proposed:active {
    opacity: .3;
    border-style: hidden;
}
