.WhoisitBoard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 0 0;
}

@media screen and (max-width: 64em) {
  .WhoisitBoard {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.WhoisitControls {
  display: flex;
  flex-direction: column;
  flex: 0 0 26rem;
  margin: 0 2rem 2rem 2rem;
  padding: 3rem;
  background: rgba(244, 244, 244, 0.8);
  border-radius: 5px;
}

@media screen and (max-width: 64em) {
  .WhoisitControls {
    flex: 1;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 36rem;
    margin: 0 0 2rem;
    padding: 2rem;
  }
}

.WhoisitPickCard {
  margin: 0 0 2rem;
}

@media screen and (max-width: 64em) {
  .WhoisitPickCard {
    margin: 1rem 0 0;
  }
}

.WhoisitPickCardButton.button {
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 64em) {
  .WhoisitPickCardButton.button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: left;
    padding: 2rem;
  }
}

.WhoisitPickCardButtonIcon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 0 1rem -2px 0;
  fill: #fff;
}

@media screen and (max-width: 64em) {
  .WhoisitPickCardButtonIcon {
    height: 4rem;
    flex: 0 0 4rem;
    margin: 0 2rem 0 0;
  }
}

.WhoisitAvailableCardsCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 0;
  font-size: 12px;
}

.WhoisitPickedCard {
  display: flex;
  flex: 0 0 26rem;
  height: 30rem;
  padding: 3rem;
  background: rgba(244, 244, 244, 0.8);
  border-radius: 5px;
  margin: 0 2rem 2rem 2rem;
}

@media screen and (max-width: 64em) {
  .WhoisitPickedCard {
    justify-content: center;
    height: auto;
    width: 100%;
    max-width: 36rem;
  }
}
