.Taboo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TabooLoading {
  flex: 1;
  display: flex;
  padding: 3rem 0 0;
}

.BackToGames {
  align-self: flex-start;
}

.SubmitDifficulty button,
.SubmitCategory button {
  color: red;
}
