.GameStartRound {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    text-align: center;
    background-image: url('~assets/images/games/hangedman_board.png');
    background-size: cover;
}

.GameStartRoundLabel {
    font-size: 1.3rem;
    margin: 0 0 2rem;
    text-align: center;
    color: #b5c7bf;
}

.GameStartRound input {
    width: 30rem;
    margin: 0;
    font-size: 2rem;
    text-align: center;
    background: #c9e9d8;
}

@media screen and (max-width: 64em) {
    .GameStartRound input {
        width: 100%;
    }
}

.GameStartRound .GameStartRoundSubmitButton.button {
    color: #e1ebe8;
    background-color: #025e2d;
    background-color: #009654;
}

.GameStartRound .GameStartRoundSubmitButton.button:hover {
    background-color: #177c47;
    background-color: #00bd77;
}

.GameStartRoundInputLegend {
    width: 30rem;
    margin: 0.5rem 0 0;
    text-align: center;
    font-size: .85rem;
    color: #888;
    color: #78a99a;
}

@media screen and (max-width: 64em) {
    .GameStartRoundInputLegend {
        width: 100%;
    }
}

.GameStartRound button {
    margin: 2rem 0 0;
}
